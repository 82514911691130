import { Button, Card, CardContent, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";
import { ErrorMessage, Formik } from "formik";
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useState } from "react";
import { number, object } from "yup";

import { LoggedOutBar } from "../../components/AppBar";
import InfoIconDrawer from "../../components/Drawer/InfoIconDrawer";
import Modal from "../../components/Drawer/Modal";
import Form from "../../components/Form/Form";
import FormErrorMessage from "../../components/Form/FormErrorMessage";
import NumberInput from "../../components/Form/Input/Number";
import { InternalButtonLink } from "../../components/Link";
import { BackLink } from "../../components/Link/InternalLink";
import { OverviewLoginType } from "../../components/Overview";
import { SEO } from "../../components/SEO";
import { BodyTextM, HeadingL, HeadingM } from "../../components/Typography";
import { usePrimaryAPIClient } from "../../context/PrimaryAPIClient";
import { richImageData } from "../../utils";

export interface imageProps {
	ing: {
		publicURL: string;
	};
	aegon: {
		publicURL: string;
	};
}

const introImage = (): imageProps => {
	return useStaticQuery(
		graphql`
			query RozcestnikLifeImages {
				ing: file(relativePath: { eq: "ing.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				aegon: file(relativePath: { eq: "aegon.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	);
};

export const AppBar = (): JSX.Element => <LoggedOutBar />;

function RozcestnikPage(): JSX.Element {
	const images = richImageData(introImage());
	const [isOpen, setIsOpen] = useState(false);
	const [localError, setLocalError] = useState(false);
	const { contractsApi } = usePrimaryAPIClient();

	return (
		<>
			<OverviewLoginType alt="Přihlášení">
				<BackLink to="/rozcestnik/" sx={{ mb: 4 }} />
				<HeadingL>Životní pojištění</HeadingL>
				<Formik
					initialValues={{ contractId: undefined }}
					validationSchema={object().shape({
						contractId: number().required(),
					})}
					onSubmit={async (values) => {
						setLocalError(false);
						try {
							const result = await contractsApi.isContractPresentGet({
								contractId: values.contractId.toString(),
							});
							if (result.isPresent) {
								await navigate("/prihlaseni-nebo-registrace/", {
									replace: true,
								});
							} else {
								window.location.href = "https://mojenn20.nn.cz";
							}
						} catch (error) {
							setLocalError(true);
						}
					}}
				>
					<>
						<Form spacing={2}>
							<NumberInput
								label="Zadejte číslo smlouvy"
								name="contractId"
								placeholder="např. 12345678"
							/>
							<Button
								variant="light"
								size="small"
								sx={{
									alignSelf: "flex-start",
									pl: 0,
									color: "primary.main",
								}}
								onClick={() => setIsOpen(true)}
							>
								Kde najdu číslo smlouvy?
							</Button>
							<Modal
								onClose={() => setIsOpen(false)}
								onOpen={() => setIsOpen(false)}
								open={isOpen}
							>
								<InfoIconDrawer
									bottomButtonAction={() => setIsOpen(false)}
									title="Kde najdu číslo smlouvy?"
									description="Číslo smlouvy najdete v dopise od nás (třeba pro daňové potvrzení nebo na smlouvě samotné). Můžete se také podívat do svých pravidelných plateb životka, kde najdete číslo smlouvy ve variabilním symbolu. Pokud variabilní symbol začíná 88, jedná se pouze o prefix a číslo smlouvy je až za ním (např z VS 8810203040 je číslo smlouvy 10203040, tj. osmi místné)."
								/>
							</Modal>
						</Form>
						{localError && (
							<ErrorMessage
								render={() => (
									<FormErrorMessage name="local-error">
										Nastala chyba, zkuste to prosím později.
									</FormErrorMessage>
								)}
							/>
						)}
					</>
				</Formik>
			</OverviewLoginType>
			<Card>
				<CardContent>
					<HeadingM>
						Pokud nevíte, jaké číslo má Vaše smlouva, koukněte na typ produktu:
					</HeadingM>
					<BodyTextM>
						<ul data-clear>
							<li>
								Smlouva byla původně sjednána u{" "}
								<GatsbyImage image={images.ing} alt="ING" />.
							</li>
						</ul>
						<Stack direction="row" spacing={4}>
							<Stack>
								<ul data-clear>
									<li>NN Smart</li>
									<li>NN Život</li>
									<li>Kapitálové ŽP</li>
								</ul>
							</Stack>
							<Stack>
								<ul data-clear>
									<li>Rodina</li>
									<li>NN Proaktiv</li>
									<li>Investor</li>
								</ul>
							</Stack>
						</Stack>
					</BodyTextM>
					<InternalButtonLink
						to="/prihlaseni-nebo-registrace/"
						sx={{ width: "100%", mt: 4 }}
					>
						Pokračovat na přihlášení
					</InternalButtonLink>
					<Divider sx={{ my: 4 }} />
					<BodyTextM>
						<ul>
							<li>
								Smlouva byla původně sjednána u{" "}
								<GatsbyImage image={images.aegon} alt="AEGON" />.
							</li>
							<li>NN Orange Risk</li>
							<li>NN Bonus</li>
							<li>NN Orange Invest</li>
						</ul>
					</BodyTextM>
					<InternalButtonLink
						to="https://mojenn20.nn.cz"
						sx={{ width: "100%" }}
					>
						Pokračovat na přihlášení
					</InternalButtonLink>
				</CardContent>
			</Card>
		</>
	);
}

export default RozcestnikPage;

export const Head = (): JSX.Element => (
	<SEO
		description="Vyberte si typ svého životního pojištění, na které se chcete podívat."
		title="Rozcestník - životko"
	/>
);
